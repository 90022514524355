import React, { useState, useEffect } from "react";
import { Layout, Menu, Modal, Drawer, Divider, Dropdown } from "antd";
import "../styles/homescreen.css";
import {
  Link,
  useLocation,
  useParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { isAuthenticated, logUser } from "../api/requests";
import Icon, {
  MenuOutlined,
  DownOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import NutritionConfirmationModal from "./NutritionConfirmationModal";
import { useTranslation } from "react-i18next";
import FitflexLogo from "../assets/images/logo_ff1e1e.png";

const { Header } = Layout;

const SiteHeader = () => {
  const [token, setToken] = useState(isAuthenticated());
  const [visible, setVisible] = useState();
  const [focus, setFocus] = useState(false);
  const [showpopup, setShowpopup] = useState(false);
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  // const [loading, setLoading] = useState(true);
  // i18n.changeLanguage(localStorage.getItem("language"));
  const [params] = useSearchParams();
  console.log("header params are :::", params.get("user"));
  let whatsappParam = params.get("user");

  const [lang, setLang] = useState(
    localStorage.getItem("language") || i18n.language
  );
  const navigate = useNavigate();
  let encryptedMsisdn = JSON.parse(localStorage.getItem("encryptedMsisdn"));
  console.log("encrypted Msisdn is :::", encryptedMsisdn);
  let country = localStorage.getItem("country");
  useEffect(() => {
    i18n.changeLanguage(lang);
    setLoading(false);
  }, [lang, i18n]);
  const menu_id = (
    <Menu onClick={handleMenuClick} className="menu_item">
      {/* <Menu.Item key="1">Arabic</Menu.Item> */}
      <Menu.Item key="1">English</Menu.Item>
      {/* <Menu.Item key="2">Arabic</Menu.Item> */}
      <Menu.Item key="3">Bhasha</Menu.Item>
    </Menu>
  );

  const menu_settings = (
    <Menu className="menu_item">
      <Menu.Item
        key="1"
        onClick={() => {
          navigate("/LpAboutYou");
        }}
      >
        {t("about_you")}
      </Menu.Item>

      {encryptedMsisdn === null && country === "ZA" ? (
        <Menu.Item
          key="2"
          onClick={() => {
            window.location.href = "http://fitflexapp.com/sa_callback/";
          }}
        >
          {t("subscription_package")}
        </Menu.Item>
      ) : (
        country !== "IND" && (
          <Menu.Item
            key="2"
            onClick={() => {
              navigate("/plans");
            }}
          >
            {t("subscription_package")}
          </Menu.Item>
        )
      )}
    </Menu>
  );

  const logWhatsappUser = () => {
    let user = {
      page: "link_to_login_btn",
      query: window.location.href,
    };
    logUser(user)
      .then((response) => {
        console.log("error is ", response);
        if (response.status === 200) {
          console.log("logged");
        }
      })
      .catch((error) => {
        console.log("error is ::::", error);
      });
  };

  function handleMenuClick(e) {
    console.log("click", e);
    e.key === "1"
      ? toggleClick("en")
      : e.key === "2"
      ? toggleClick("ar")
      : toggleClick("id");
    // console.log("LANGUA : ", i18n.language);
  }
  function toggleClick(language) {
    console.log("language is :::", language);
    i18n.changeLanguage(language);
    setLang(language);
    localStorage.setItem("language", language);
    setLoading(false);
  }

  const focusBtn = () => {
    setFocus(true);
  };

  const Logout = () => {
    window.location.href = "/login";
    localStorage.clear();
    setToken(false);
    setShowpopup(false);
    // navigate("/login");
  };
  const onClose = () => {
    setVisible(false);
  };
  const handleCancel = () => {
    setShowpopup(false);
  };
  // const onChange = (e) => {
  //   this.setState({
  //    setPlacement(e.target.value)
  //   });
  // };
  const showDrawer = () => {
    setVisible(true);
  };

  const location = useLocation();
  const menu = (
    <Menu>
      <Menu.Item
      // onClick={() => {
      //   window.location.reload();
      // }}
      >
        <Link
          to={token === false ? "/login" : "/LpAboutYou"}
          state={{ route: "settings" }}
        >
          {t("about_you")}
        </Link>
      </Menu.Item>
      <Menu.Item
      // onClick={() => {
      //   window.location.reload();
      // }}
      >
        {/* <Link
          to={token === false ? "/login" : "/settings/goals"}
          state={{ route: "settings" }}
        >
          {t("fitness_goal")}
        </Link>
      </Menu.Item>
      <Menu.Item
      // onClick={() => {
      //   window.location.reload();
      // }}
      > */}
        {encryptedMsisdn === null && country === "ZA" ? (
          <a href={"http://fitflexapp.com/sa_callback/"}>
            {t("subscribed_package")}
          </a>
        ) : (
          country !== "IND" && (
            <Link
              to={token === false ? "/login" : "/plans"}
              state={{ route: "settings" }}
            >
              {t("subscribed_package")}
            </Link>
          )
        )}
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <Header
        style={{
          position: "fixed",
          height: "65px",
          marginTop: "-4px",
        }}
        className="hide-header"
      >
        <Menu
          theme="dark"
          mode="horizontal"
          style={{
            height: "60px",
            margin: "0 auto",
          }}
          selectedKeys={location.pathname}
          className="menu-class-header"
        >
          <Menu.Item>
            <Link to="/" style={styles.logo}>
              {/* FITFLEX */}
              <img
                src={FitflexLogo}
                style={{
                  width: 70,
                  marginTop: 10,
                  // height: 40,

                  // border: "1px solid green",
                }}
              />
            </Link>
          </Menu.Item>
          <Menu.Item className="header-menu-item" key={"/homepage"}>
            <Link to={token === false ? "/login" : "/homepage"}>
              {t("home")}
            </Link>
          </Menu.Item>
          <Menu.Item className="header-menu-item" key={"/alltrainingplans"}>
            <Link to="/alltrainingplans">{t("training")}</Link>
          </Menu.Item>
          <Menu.Item className="header-menu-item" key={"/workoutvideos"}>
            <Link
              to={token === false ? "/login" : "/workoutvideos"}
              state={{ route: "workout" }}
            >
              {t("workout_videos")}
            </Link>
          </Menu.Item>
          <Menu.Item className="header-menu-item">
            <Link to="/nutrition">{t("nutrition")}</Link>
          </Menu.Item>
          <Menu.Item className="header-menu-item" key={"/mytrainingplan"}>
            <Link
              to={token === false ? "/login" : "/mytrainingplan"}
              state={{ route: "planner" }}
            >
              {t("planner")}
            </Link>
          </Menu.Item>
          {/* <Menu.Item className="header-menu-item" key={"/blog"}>
            <Link to="/blog">{t("blogs")}</Link>
          </Menu.Item> */}
          {/* <Menu.Item className="header-menu-item" key={"/career"}>
            <Link to="/career">Careers</Link>
          </Menu.Item> */}
          <Menu.Item className="header-menu-item" key={"/exercise"}>
            <Link to="/exercise">{t("exercise")}</Link>
          </Menu.Item>
          {token !== false && country === "IND" && (
            <Menu.Item className="header-menu-item" key={"/game"}>
              <Link to="/game">Quiz</Link>
            </Menu.Item>
          )}

          <Menu.Item
            className="header-menu-item"
            // style={{ display: token === false ? "none" : "flex" }}
            key={"/settings"}
          >
            {/* <Link
              style={{
                display: token === false ? "none" : "flex",
                cursor: token === false ? "none" : "pointer",
              }}
              to="/settings/about"
              state={{ route: "settings" }}
            >
              {t("settings")}
            </Link> */}
            <Dropdown overlay={menu_settings}>
              <a className="header-menu-item" style={{ border: "none" }}>
                {t("settings")}
              </a>
            </Dropdown>
          </Menu.Item>
          {/* {localStorage.getItem("country") === "PK" && (
            <Menu.Item>
              <a
                className="header-menu-item"
                href={"https://shop.fitflexapp.com"}
                style={{ border: "none" }}
                target="_blank"
              >
                Shop
              </a>
            </Menu.Item>
          )} */}
          <Menu.Item>
            <Dropdown overlay={menu_id}>
              <a className="dropdown">
                {lang.toUpperCase()}
                <GlobalOutlined
                  style={{ color: "#ff1e1e", marginLeft: "20px" }}
                />
              </a>
            </Dropdown>
          </Menu.Item>

          <Menu.Item key={"/login"}>
            {token === false ? (
              <>
                <Link
                  style={styles.btn1}
                  to={"/login"}
                  state={{ home: "home" }}
                  onClick={() => {
                    if (whatsappParam && whatsappParam === "SMSuser") {
                      logWhatsappUser();
                    }
                  }}
                >
                  {t("signup")}
                </Link>
                <Link
                  style={styles.btn2}
                  to={"/login"}
                  state={{ home: "home" }}
                  onClick={() => {
                    if (whatsappParam && whatsappParam === "SMSuser") {
                      logWhatsappUser();
                    }
                  }}
                >
                  {t("login")}
                </Link>
              </>
            ) : (
              // <Link
              //   style={styles.btn1}
              //   to="/"
              //   size="small"
              // onClick={() => {
              //   setShowpopup(true);
              // }}
              // >
              <div
                style={{
                  height: "26px",
                  display: "flex",
                  border: " 1px solid red",
                  justifySelf: "center",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  paddingTop: "0px",
                  marginTop: "17px",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: 600,
                  color: "white",
                  background: "#ff1e1e",
                  borderRadius: "5px",
                }}
                onClick={() => {
                  setShowpopup(true);
                }}
              >
                {t("logout")}
              </div>
              // </Link>
            )}
          </Menu.Item>
        </Menu>
      </Header>

      <Header
        className="hidden-sidebar"
        style={{
          position: "fixed",
          height: "65px",
          marginTop: "-4px",
          // zIndex: "9999",
        }}
      >
        <div
          style={{
            color: "white",
            whiteSpace: "noWrap",
            display: "flex",
            placeSelf: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MenuOutlined
            onClick={showDrawer}
            // uncomment above line when functioning required
            style={{
              marginRight: "20px",
              marginLeft: "-30px",
              fontSize: "24px",
              placeSelf: "center",
              marginTop: "2px",
            }}
          />

          <Link to="/home" style={styles.logo}>
            {/* FITFLEX */}
            <img
              src={require("../assets/images/logo_ff1e1e.png").default}
              style={{
                width: 50,
                height: 30,
                // border: "1px solid green",
              }}
            />
          </Link>
        </div>

        <Drawer
          title="Fitflex"
          placement={"left"}
          closable={false}
          onClose={onClose}
          visible={visible}
          key={"left"}
          drawerStyle={{
            backgroundColor: "black",
          }}
          headerStyle={{
            backgroundColor: "black",
          }}
          width={"300"}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Link to={"/homepage"} className="sidebar-menu-text">
              {t("home")}
            </Link>
            <Divider />
            <Link to={"/alltrainingplans"} className="sidebar-menu-text">
              {t("training")}
            </Link>
            <Divider />
            <Link
              to={token === false ? "/login" : "/workoutvideos"}
              state={{ route: "workout" }}
              className="sidebar-menu-text"
            >
              {t("workout_videos")}
            </Link>
            <Divider />
            <Link to={"/nutrition"} className="sidebar-menu-text">
              Nutrition
            </Link>
            <Divider />
            <Link
              to={token === false ? "/login" : "/mytrainingplan"}
              state={{ route: "planner" }}
              className="sidebar-menu-text"
            >
              {t("planner")}
            </Link>
            {/* <Divider />
            <Link to={"/blog"} className="sidebar-menu-text">
              {t("blogs")}
            </Link> */}

            <Divider />
            <Link to={"/exercise"} className="sidebar-menu-text">
              {t("exercise")}
            </Link>
            {token !== false && country === "IND" && (
              <>
                <Divider />
                <Link to={"/game"} className="sidebar-menu-text">
                  Game
                </Link>
              </>
            )}

            <Divider />

            {/* <Divider />
            <Link to={"/career"} className="sidebar-menu-text">
              Careers
            </Link> */}

            <Dropdown overlay={menu_id}>
              <a className="dropdown" style={{ width: "40%" }}>
                {lang.toUpperCase()}
                <GlobalOutlined
                  style={{ color: "#ff1e1e", marginLeft: "20px" }}
                />
              </a>
            </Dropdown>
            <Divider />
            {/* <Link to={"/settings"} className="sidebar-menu-text">
              Settings
              <DownOutlined style={{ marginLeft: "50px" }} />
            </Link> */}
            <Dropdown overlay={menu} trigger={["click"]}>
              <p
                onClick={(e) => e.preventDefault()}
                className="sidebar-menu-text"
              >
                {t("settings")}
                <DownOutlined
                  style={{
                    marginLeft: "50px",
                    fontSize: "18px",
                    marginTop: "5px",
                  }}
                />
              </p>
            </Dropdown>
          </div>
        </Drawer>
        {token === false ? (
          <Link
            to={"/login"}
            state={{ home: "home" }}
            className="small-signup-btn"
          >
            {t("signup")}
          </Link>
        ) : (
          <div
            to={"/login"}
            state={{ home: "home" }}
            className="small-signup-btn"
            onClick={() => {
              setShowpopup(true);
            }}
          >
            {t("logout")}
          </div>
        )}
      </Header>

      <Modal
        visible={showpopup}
        onCancel={handleCancel}
        title={"Confirmation"}
        className="custom-modal-styles"
        bodyStyle={{
          height: "auto",
        }}
        footer={false}
      >
        <div>
          <p style={{ color: "white" }}>{t("logout_message")}</p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Link
              to="/"
              onClick={Logout}
              style={{
                background: "#ff1e1e",
                color: "white",
                paddingLeft: "10px",
                paddingRight: "10px",
                cursor: "pointer",
              }}
            >
              {t("yes")}
            </Link>
            <button
              onFocus={focusBtn}
              onClick={() => {
                setShowpopup(false);
                setFocus(false);
              }}
              style={{
                background: !focus ? "grey" : "orange",
                color: "white",
                paddingLeft: "10px",
                paddingRight: "10px",
                cursor: "pointer",
              }}
            >
              {t("no")}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SiteHeader;

const styles = {
  logo: {
    fontFamily: "Gotham",
    fontStyle: "italic",
    fontWeight: "normal",
    fontSize: 18,
    color: "#ff1e1e",
    textTransform: "none",
    marginRight: 20,
    placeSelf: "center",
  },
  btn1: {
    borderRadius: 5,
    backgroundColor: "rgba(255, 30, 30, 1)",
    color: "#fff",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 5,
    paddingBottom: 5,
    textTransform: "uppercase",
    fontFamily: "Montserrat",
    fontWeight: "500",
    fontSize: 12,
    height: 60,
    justifyContent: "center",
    border: "rgba(255, 30, 30, 1)",
    marginRight: 3,
    marginLeft: 70,
  },
  btn2: {
    borderRadius: 5,
    // backgroundColor: "rgba(0, 0, 0, 1)",
    color: "#fff",
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: "uppercase",
    fontFamily: "Montserrat",
    fontWeight: "500",
    fontSize: 12,
    height: 30,
    justifyContent: "center",
    border: "rgba(0, 0, 0, 1)",
    zIndex: 1,
  },
  itemStyleNone: {
    color: "#000",
    cursor: "default",
    // fontSize: "1px",
  },
};
